<!--
 * @Description:价格体系列表
 * @Author: ChenXueLin
 * @Date: 2022-03-04 09:28:40
 * @LastEditTime: 2022-07-15 16:02:31
 * @LastEditors: ChenXueLin
-->
<template>
  <div class="content-view-wrapper pd-10">
    <div
      class="content-view section-wrapper"
      ref="sectionWrapper"
      v-loading="loading"
      :element-loading-background="loadingBackground"
    >
      <!-- 搜索头部 start -->
      <table-title @resize="handleTableTitleResize">
        <template v-slot:searchForm>
          <el-form class="search-list" ref="searchForm" :model="searchForm">
            <el-form-item class="search-item--1" prop="serviceNo">
              <el-input
                v-model="searchForm.serviceNo"
                placeholder="服务商编号"
                title="服务商编号"
              ></el-input>
            </el-form-item>
            <el-form-item prop="serviceProvider">
              <el-input
                v-model="searchForm.serviceProvider"
                placeholder="服务商名称"
                title="服务商名称"
              ></el-input>
            </el-form-item>
            <el-form-item class="search-item--1" prop="feiShuContractNo">
              <el-input
                v-model="searchForm.feiShuContractNo"
                placeholder="飞书合同编号"
                title="飞书合同编号"
              ></el-input>
            </el-form-item>
            <el-form-item prop="invoiceType">
              <e6-vr-select
                v-model="searchForm.invoiceType"
                :data="invoiceTypeList"
                placeholder="发票类型"
                title="发票类型"
                clearable
                :props="{
                  id: 'codeValue',
                  label: 'codeName'
                }"
              ></e6-vr-select>
            </el-form-item>
            <el-form-item prop="serviceProviderStatus">
              <e6-vr-select
                v-model="searchForm.serviceProviderStatus"
                :data="serviceProviderStatusList"
                placeholder="服务商状态"
                title="服务商状态"
                clearable
                :props="{
                  id: 'codeValue',
                  label: 'codeName'
                }"
              ></e6-vr-select>
            </el-form-item>
            <el-form-item prop="companyList">
              <e6-vr-select
                v-model="searchForm.companyList"
                :data="companyList"
                placeholder="支付主体"
                title="支付主体"
                clearable
                multiple
                virtual
                :is-title="true"
                :props="{
                  id: 'codeValue',
                  label: 'codeName'
                }"
              ></e6-vr-select>
            </el-form-item>
            <el-form-item class="search-item--2" prop="effectTime">
              <e6-date-picker
                popper-class="no-atTheMoment"
                label="生效开始时间"
                ref="effectiveDatePicker"
                v-model="searchForm.effectTime"
                title="生效开始时间"
                :picker-options="pickerOptions('searchForm.effectTime')"
                :default-time="['00:00:00', '23:59:59']"
                start-placeholder="生效开始时间（始）"
                end-placeholder="生效开始时间（止）"
              ></e6-date-picker>
            </el-form-item>
            <el-form-item class="search-item--2" prop="abortTime">
              <e6-date-picker
                popper-class="no-atTheMoment"
                label="生效截至时间"
                ref="effectiveDatePicker"
                v-model="searchForm.abortTime"
                title="生效截至时间"
                :picker-options="pickerOptions('searchForm.abortTime')"
                :default-time="['00:00:00', '23:59:59']"
                start-placeholder="生效截至时间（始）"
                end-placeholder="生效截至时间（止）"
              ></e6-date-picker>
            </el-form-item>
            <el-form-item class="search-item--buttons">
              <el-button type="primary" @click="handleSearch">查询</el-button>
              <el-button class="reset" @click="handleReset">重置</el-button>
            </el-form-item>
          </el-form>
        </template>
        <template v-slot:operateAction>
          <i class="e6-icon-add_line" title="新增" @click="handleAdd"></i>
          <i
            class="e6-icon-export_fill"
            title="导出"
            @click="exportData(false)"
          ></i>
          <i
            class="e6-icon-Import_fill"
            title="导入"
            @click="importXlxs(0)"
          ></i>
          <el-button type="primary" @click="importXlxs(1)"
            >批量修改导入</el-button
          >
          <el-button type="primary" @click="exportData(true)"
            >批量修改导出</el-button
          >
        </template>
      </table-title>
      <!-- 搜索头部 end -->
      <!-- 表格 start -->
      <section class="table-wrapper">
        <el-table
          border
          :height="resizeViewHeight + 'px'"
          :data="tableData"
          highlight-current-row
          class="elTable"
          ref="elTable"
        >
          <!-- 序号 start -->
          <el-table-column
            label="序号"
            width="50"
            fixed="left"
            header-align="center"
            align="center"
          >
            <template slot-scope="scope">
              <span>
                {{
                  scope.$index +
                    1 +
                    (searchForm.pageIndex - 1) * searchForm.pageSize
                }}
              </span>
            </template>
          </el-table-column>
          <!-- 序号 end -->
          <el-table-column
            show-overflow-tooltip
            v-for="(column, index) in columnData"
            :key="index"
            :prop="column.fieldName"
            :label="column.fieldLabel"
            :min-width="column.width"
            :fixed="column.fixed"
            :align="column.align"
            header-align="center"
          >
          </el-table-column>
          <el-table-column
            fixed="right"
            align="center"
            label="操作"
            width="130"
          >
            <template slot-scope="scope">
              <e6-td-operate
                :data="getOperateList(scope.row)"
                @command="handleOperate($event, scope.row)"
              ></e6-td-operate>
            </template>
          </el-table-column>
        </el-table>
      </section>
      <!-- 表格 end -->

      <!-- 分页 start -->
      <section class="pagination-wrapper fixed-section" ref="paginationWrapper">
        <el-pagination
          :page-size.sync="searchForm.pageSize"
          :current-page.sync="searchForm.pageIndex"
          :page-sizes="pageSizes"
          :layout="layout"
          :total="total"
          background
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
        ></el-pagination>
      </section>
      <!-- 分页 end -->
    </div>
    <addPrice
      :addVisible.sync="addVisible"
      :addType="addType"
      :clickRow="clickRow"
      @refresh="handleSearch"
    ></addPrice>
    <price-detail
      :detailVisible.sync="detailVisible"
      :clickRow="clickRow"
      @refresh="handleSearch"
    ></price-detail>
    <!-- 操作日志 -->
    <log-dialog
      :logVisible.sync="logVisible"
      :businessId="clickRow.servicePriceId"
      logType="2"
    ></log-dialog>
  </div>
</template>

<script>
import base from "@/mixins/base";
import listPage from "@/mixins/list-page";
import listPageReszie from "@/mixins/list-page-resize";
import { printError } from "@/utils/util";
import { exportMessageHandle, exportExcelByUrl } from "@/utils/download";
import {
  getServicePriceListPage,
  findDownList,
  exportServicePrice
} from "@/api";
import addPrice from "./addPrice.vue";
import PriceDetail from "./priceDetail.vue";
import LogDialog from "../../../components/logDialog.vue";
export default {
  name: "priceManagement",
  data() {
    return {
      queryListAPI: getServicePriceListPage,
      searchForm: {
        serviceNo: "",
        serviceProvider: "",
        feiShuContractNo: "",
        serviceProviderStatus: "",
        invoiceType: "",
        companyList: [],
        effectTime: [],
        effectStartTime: "",
        effectEndTime: "",
        abortTime: [],
        abortStartTime: "",
        abortEndTime: "",
        pageIndex: 1,
        pageSize: 20
      },
      companyList: [],
      invoiceTypeList: [],
      serviceProviderStatusList: [],
      total: 0,
      loading: false,
      columnData: [
        {
          fieldName: "serviceNo",
          display: true,
          fieldLabel: "服务商编号",
          width: 160,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "serviceProvider",
          display: true,
          fieldLabel: "服务商名称",
          width: 140,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "serviceProviderStatusName",
          display: true,
          fieldLabel: "服务商状态",
          width: 110,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "startTimeStr",
          display: true,
          fieldLabel: "服务生效时间",
          width: 120,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "endTimeStr",
          display: true,
          fieldLabel: "服务截至时间",
          width: 120,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "feiShuContractNo",
          display: true,
          fieldLabel: "飞书合同编号",
          width: 140,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "companyStr",
          display: true,
          fieldLabel: "支付主体",
          width: 180,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "taxRate",
          display: true,
          fieldLabel: "税率(%)",
          width: 90,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "invoiceTypeName",
          display: true,
          fieldLabel: "发票类型",
          width: 90,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "serviceRate",
          display: true,
          fieldLabel: "服务费率(%)",
          width: 90,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "serviceRule.unpackAndUnpackFee",
          display: true,
          fieldLabel: "拆机单_拆机费（元）",
          width: 140,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "serviceRule.notUnpackAndUnpackFee",
          display: true,
          fieldLabel: "非拆机单_拆机费（元）",
          width: 140,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "serviceRule.repairFee",
          display: true,
          fieldLabel: "维修费（元）",
          width: 120,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "serviceRule.nightFee",
          display: true,
          fieldLabel: "夜间费（元）",
          width: 120,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "serviceRule.emptyRunFee",
          display: true,
          fieldLabel: "空跑费（元）",
          width: 120,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "serviceRule.roadRepairFee",
          display: true,
          fieldLabel: "路补单价（元）",
          width: 120,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "serviceRule.emptyRunDeduction",
          display: true,
          fieldLabel: "空跑扣除公里",
          width: 120,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "serviceRule.notEmptyRunDeduction",
          display: true,
          fieldLabel: "非空跑扣除公里",
          width: 120,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "operationUser",
          display: true,
          fieldLabel: "操作人",
          width: 160,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "updateDateStr",
          display: true,
          fieldLabel: "最后修改时间",
          width: 160,
          disabled: false,
          fixed: false,
          align: "center"
        }
      ], //表头
      tableData: [], // 表格数据
      addVisible: false,
      clickRow: {},
      detailVisible: false,
      addType: 1, //1新增2编辑
      logVisible: false
    };
  },
  mixins: [listPage, base, listPageReszie],
  components: {
    addPrice,
    PriceDetail,
    LogDialog
  },
  computed: {},
  watch: {
    "searchForm.effectTime": {
      immediate: true,
      handler(val) {
        if (val[0] && val[1] && val[0] > val[1]) {
          this.searchForm.effectTime = this.searchForm.effectTime.reverse();
        }
        this.searchForm.effectStartTime = val[0] || "";
        this.searchForm.effectEndTime = val[1] || "";
      }
    },
    "searchForm.abortTime": {
      immediate: true,
      handler(val) {
        if (val[0] && val[1] && val[0] > val[1]) {
          this.searchForm.abortTime = this.searchForm.abortTime.reverse();
        }
        this.searchForm.abortStartTime = val[0] || "";
        this.searchForm.abortEndTime = val[1] || "";
      }
    }
  },
  created() {
    this.initData();
    this.queryList();
  },
  mounted() {
    this.setTableScrollDOM("elTable");
  },
  activated() {
    this.setScrollRecord();
  },
  methods: {
    async initData() {
      try {
        this.selectLoading = true;
        let promiseList = [
          findDownList([
            "invoiceType",
            "serviceProviderStatus",
            "serviceCompanyList"
          ])
        ];
        let [enumRes] = await Promise.all(promiseList);
        //主体
        this.companyList = this.getFreezeResponse(enumRes, {
          path: "data.serviceCompanyList"
        });
        //发票类型
        this.invoiceTypeList = this.getFreezeResponse(enumRes, {
          path: "data.invoiceType"
        });
        //服务商状态
        this.serviceProviderStatusList = this.getFreezeResponse(enumRes, {
          path: "data.serviceProviderStatus"
        });
      } catch (error) {
        printError(error);
      } finally {
        this.selectLoading = false;
      }
    },
    // 获取页面操作权限
    getOperateList() {
      // 页面按钮权限
      let operates = [
        {
          label: "编辑",
          id: 1
        },
        {
          label: "详情",
          id: 2
        },
        {
          label: "操作日志",
          id: 3
        }
      ];
      return operates;
    },
    // 操作--更多
    handleOperate(val, row) {
      this.clickRow = row;
      if (val.id == 1) {
        this.addVisible = true;
        this.addType = 2;
      }
      if (val.id == 2) {
        this.detailVisible = true;
      }
      if (val.id == 3) {
        this.logVisible = true;
      }
    },
    //新增
    handleAdd() {
      this.addType = 1;
      this.addVisible = true;
    },
    // 导入
    importXlxs(type) {
      if (type == 0) {
        this.routerPush({
          name: "priceManagement/uploadXlsx",
          params: {
            refresh: true,
            moduleKey: "priceManagement"
          }
        });
      } else {
        this.routerPush({
          name: "priceManagement/batchUploadXlsx",
          params: {
            refresh: true,
            moduleKey: "batchUploadXlsx"
          }
        });
      }
    },
    //  导出
    exportData(forImportTemplate) {
      this.exportDataReq(exportServicePrice, forImportTemplate);
    },
    async exportDataReq(exportApi, forImportTemplate) {
      try {
        this.loading = true;
        let res = await exportApi({ ...this.searchForm, forImportTemplate });
        if (res.code == "OK") {
          let url = this.getFreezeResponse(res, {
            path: "data",
            defaultVal: ""
          });
          if (url) exportExcelByUrl(url);
          else {
            exportMessageHandle(this);
          }
        }
      } catch (error) {
        printError(error);
      } finally {
        this.loading = false;
      }
    }
  }
};
</script>
<style lang="scss" scoped></style>
