<!--
 * @Description: 价格体系详情
 * @Author: ChenXueLin
 * @Date: 2021-11-09 18:26:29
 * @LastEditTime: 2022-07-15 16:38:03
 * @LastEditors: ChenXueLin
-->
<template>
  <el-dialog
    v-dialogDrag
    title="价格体系详情"
    :visible.sync="visible"
    width="80%"
    :before-close="closeDialog"
    :close-on-click-modal="false"
    append-to-body
    v-loading="dialogLoading"
    :element-loading-background="loadingBackground"
    custom-class="add-concat-dialog"
    @open="openDialog"
  >
    <el-tabs v-model="activeName">
      <el-tab-pane label="价格体系" name="1">
        <div class="dialogTitle">
          <div class="line"></div>
          <div class="til">基础信息</div>
        </div>
        <el-form
          ref="basicInfoForm"
          label-width="100px"
          :model="basicInfoForm"
          :inline="true"
        >
          <el-row>
            <el-col :span="8">
              <el-form-item label="服务商名称:" prop="serviceProvider">
                {{ basicInfoForm.serviceProvider }}
              </el-form-item>
            </el-col>
            <el-col :span="7">
              <el-form-item label="服务商编号:" prop="serviceNo">
                {{ basicInfoForm.serviceNo }}
              </el-form-item>
            </el-col>
            <el-col :span="7">
              <el-form-item label="飞书合同编号:" prop="feiShuContractNo">
                {{ basicInfoForm.feiShuContractNo }}
              </el-form-item>
            </el-col>
            <el-col :span="10">
              <el-form-item label="生效周期:">
                {{ basicInfoForm.startTime }}~{{ basicInfoForm.endTime }}
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="服务费税率(%):" prop="serviceRate">
                {{ basicInfoForm.serviceRate }}
              </el-form-item>
            </el-col>
            <el-col :span="24">
              <el-form-item label="支付主体:" prop="companyStr">
                {{ basicInfoForm.companyStr }}
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>

        <div class="dialogTitle">
          <div class="line"></div>
          <div class="til">规则配置</div>
        </div>
        <el-form
          ref="serviceRuleForm"
          label-width="110px"
          :model="serviceRuleForm"
          :inline="true"
        >
          <el-row>
            <el-col :span="8">
              <el-form-item label="拆机费规则:" prop="unpackFeeRuleStr">
                {{ serviceRuleForm.unpackFeeRuleStr }}
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="维修费规则:" prop="repairFeeRuleStr">
                {{ serviceRuleForm.repairFeeRuleStr }}
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item
                label="拆机单_ 拆机费:"
                prop="unpackAndUnpackFee"
                v-if="serviceRuleForm.unpackFeeRule == 1"
              >
                {{ serviceRuleForm.unpackAndUnpackFee }}
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item
                label="维修费:"
                prop="repairFee"
                v-if="serviceRuleForm.repairFeeRule == 1"
              >
                {{ serviceRuleForm.repairFee }}
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item
                label="非拆机单_ 拆机费:"
                prop="notUnpackAndUnpackFee"
                v-if="serviceRuleForm.unpackFeeRule == 1"
              >
                {{ serviceRuleForm.notUnpackAndUnpackFee }}
              </el-form-item>
            </el-col>
            <!-- <el-col :span="8">
              <el-form-item label="路补规则:" prop="roadRepairRuleStr">
                {{ serviceRuleForm.roadRepairRuleStr }}
              </el-form-item>
            </el-col> -->
            <el-col :span="8">
              <el-form-item label="夜间费:" prop="nightFee">
                {{ serviceRuleForm.nightFee }}
              </el-form-item>
            </el-col>
            <!-- <el-col :span="8">
              <el-form-item
                label="路补单价:"
                prop="roadRepairFee"
                v-if="
                  serviceRuleForm.roadRepairRule == 2 ||
                    serviceRuleForm.roadRepairRule == 4
                "
              >
                {{ serviceRuleForm.roadRepairFee }}
              </el-form-item>
            </el-col> -->
            <el-col :span="8">
              <el-form-item label="空跑费规则:" prop="emptyRunFeeRuleStr">
                {{ serviceRuleForm.emptyRunFeeRuleStr }}
              </el-form-item>
            </el-col>
            <!-- <el-col :span="8">
              <el-form-item
                label="空跑扣除公里:"
                prop="emptyRunDeduction"
                v-if="
                  serviceRuleForm.roadRepairRule == 2 ||
                    serviceRuleForm.roadRepairRule == 4
                "
              >
                {{ serviceRuleForm.emptyRunDeduction }}
              </el-form-item>
            </el-col> -->
            <el-col :span="8">
              <el-form-item
                label="空跑费:"
                prop="emptyRunFee"
                v-if="serviceRuleForm.emptyRunFeeRule == 1"
              >
                {{ serviceRuleForm.emptyRunFee }}
              </el-form-item>
            </el-col>
            <!-- <el-col :span="8">
              <el-form-item
                label="非空跑扣除公里:"
                prop="notEmptyRunDeduction"
                v-if="
                  serviceRuleForm.roadRepairRule == 2 ||
                    serviceRuleForm.roadRepairRule == 4
                "
              >
                {{ serviceRuleForm.notEmptyRunDeduction }}
              </el-form-item>
            </el-col> -->
            <el-col :span="8">
              <el-form-item label="配件补贴费:" prop="partSubsidyFee">
                {{ serviceRuleForm.partSubsidyFee }}
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>

        <div class="dialogTitle">
          <div class="line"></div>
          <div class="til">上门费和路补配置</div>
        </div>
        <el-table border :data="configTableData" highlight-current-row>
          <el-table-column
            show-overflow-tooltip
            align="center"
            prop="address"
            label="省市区"
            min-width="230"
          >
          </el-table-column>
          <el-table-column
            align="center"
            prop="roadRepairRuleStr"
            label="路补规则"
            min-width="120"
          >
          </el-table-column>
          <!-- <el-table-column
            show-overflow-tooltip
            align="center"
            prop="roadRepairFee"
            label="路补单价"
            min-width="100"
          >
          </el-table-column> -->
          <el-table-column
            show-overflow-tooltip
            align="center"
            prop="emptyRunDeduction"
            label="空跑扣除公里"
            min-width="100"
          >
          </el-table-column>
          <el-table-column
            show-overflow-tooltip
            align="center"
            prop="notEmptyRunDeduction"
            label="非空跑扣除公里"
            min-width="110"
          >
          </el-table-column>
          <el-table-column
            show-overflow-tooltip
            align="center"
            prop="roadSubsidy"
            label="路补一口价/非一口价单价"
            min-width="140"
          >
          </el-table-column>
          <el-table-column
            show-overflow-tooltip
            prop="reachFee"
            align="center"
            label="上门费"
            min-width="100"
          >
          </el-table-column>
        </el-table>
      </el-tab-pane>
      <el-tab-pane label="物料价格表" name="2">
        <div class="dialogTitle">
          <div class="line"></div>
          <div class="til">基础信息</div>
        </div>
        <el-form
          ref="basicInfoForm"
          label-width="100px"
          :model="basicInfoForm"
          :inline="true"
        >
          <el-form-item label="服务商名称:" prop="a">
            {{ basicInfoForm.serviceProvider }}
          </el-form-item>
          <el-form-item label="服务商编号:" prop="a">
            {{ basicInfoForm.serviceNo }}
          </el-form-item>
        </el-form>
        <div class="dialogTitle">
          <div class="line"></div>
          <div class="til">规则配置</div>
        </div>
        <el-table border :data="tableData" highlight-current-row>
          <el-table-column
            show-overflow-tooltip
            align="center"
            prop="classifyName"
            label="物料分类"
            min-width="140"
          >
          </el-table-column>
          <el-table-column
            show-overflow-tooltip
            align="center"
            prop="materialTypeName"
            label="物料类型"
            min-width="100"
          >
          </el-table-column>
          <el-table-column
            show-overflow-tooltip
            prop="installPrice"
            align="center"
            label="新装价格"
            min-width="140"
          >
          </el-table-column>
          <el-table-column
            show-overflow-tooltip
            prop="repairPrice"
            align="center"
            label="维修价格"
            min-width="140"
          >
          </el-table-column>
          <el-table-column
            show-overflow-tooltip
            prop="addPrice"
            align="center"
            label="加装价格"
            min-width="140"
          >
          </el-table-column>
          <el-table-column
            show-overflow-tooltip
            prop="unpackPrice"
            align="center"
            label="拆机价格"
            min-width="140"
          >
          </el-table-column>
          <el-table-column
            show-overflow-tooltip
            prop="isReachFee"
            align="center"
            label="是否收上门费"
            min-width="140"
          >
            <template slot-scope="scope">
              <el-switch
                v-model="scope.row.isReachFee"
                :active-value="1"
                :inactive-value="0"
                disabled
              >
              </el-switch>
            </template>
          </el-table-column>
        </el-table>
      </el-tab-pane>
    </el-tabs>
  </el-dialog>
</template>

<script>
import base from "@/mixins/base";
import { getServicePriceAddress, getServiceMaterial } from "@/api";
import { printError } from "@/utils/util";
export default {
  name: "priceDetail",
  components: {},
  data() {
    return {
      addressList: [],
      activeName: "1",
      dialogLoading: false,
      basicInfoForm: {
        serviceProvider: "",
        serviceNo: "",
        feiShuContractNo: "",
        startTime: "",
        endTime: "",
        companyStr: "",
        serviceRate: ""
      },
      serviceRuleForm: {
        // unpackFeeRule: "",
        // unpackAndUnpackFee: "",
        // notUnpackAndUnpackFee: "",
        // repairFeeRule: "",
        // repairFee: "",
        // nightFee: "",
        // emptyRunFeeRule: "",
        // emptyRunFee: "",
        // roadRepairRule: "",
        // roadRepairFee: "",
        // emptyRunDeduction: "",
        // notEmptyRunDeduction: "",
        // partSubsidyFee: ""
      },
      configTableData: [],
      tableData: []
    };
  },
  props: {
    detailVisible: {
      type: Boolean,
      default: false
    },
    clickRow: {
      type: Object,
      default: () => {}
    }
  },
  mixins: [base],
  computed: {
    visible: {
      get() {
        return this.detailVisible;
      },
      set(val) {
        this.$emit("update:detailVisible", val);
      }
    }
  },
  watch: {},
  created() {},
  methods: {
    //查询详情
    async getDetail() {
      try {
        this.dialogLoading = true;
        let promiseList = [
          getServicePriceAddress({
            servicePriceId: this.clickRow.servicePriceId
          }),
          getServiceMaterial({
            servicePriceId: this.clickRow.servicePriceId
          })
        ];
        let [addressRes, priceRes] = await Promise.all(promiseList);
        //服务商
        this.configTableData = addressRes.data;
        this.configTableData.map(item => {
          item.address = item.addressDetailList
            .map(ele => ele.fullName)
            .join(",");
        });
        //一口价
        this.tableData = priceRes.data;
      } catch (error) {
        printError(error);
      } finally {
        this.dialogLoading = false;
      }
    },
    //打开弹框
    openDialog() {
      let clickRow = _.cloneDeep(this.clickRow);
      this.basicInfoForm = {
        serviceProvider: clickRow.serviceProvider,
        serviceNo: clickRow.serviceNo,
        feiShuContractNo: clickRow.feiShuContractNo,
        startTime: clickRow.startTimeStr,
        endTime: clickRow.endTimeStr,
        companyStr: clickRow.companyStr,
        serviceRate: clickRow.serviceRate
      };
      this.serviceRuleForm = clickRow.serviceRule
        ? {
            ...clickRow.serviceRule,
            unpackFeeRuleStr: clickRow.unpackFeeRuleStr,
            repairFeeRuleStr: clickRow.repairFeeRuleStr,
            emptyRunFeeRuleStr: clickRow.emptyRunFeeRuleStr,
            roadRepairRuleStr: clickRow.roadRepairRuleStr
          }
        : {};
      this.getDetail();
    },
    //点击取消
    closeDialog() {
      this.visible = false;
    }
  }
};
</script>
<style lang="scss" scoped>
/deep/.add-concat-dialog {
  .el-dialog__body {
    padding-bottom: 10px;
    padding-left: 10px;
    padding-right: 10px;
    overflow-y: auto;
    max-height: 600px;
    .el-input {
      width: 220px;
    }
    .icon {
      i {
        margin-right: 10px;
        font-size: 20px;
        color: #46bfea;
      }
    }
    .dialogTitle {
      display: flex;
      align-items: center;
      margin-bottom: 10px;
      width: 100%;
      justify-content: flex-start;
      .line {
        width: 4px;
        height: 25px;
        background: #46bfea;
        margin-right: 5px;
      }
      .til {
        font-size: 16px;
        font-weight: 500;
        margin-right: 15px;
      }
      i {
        margin-right: 10px;
        font-size: 20px;
        color: #46bfea;
      }
    }
  }
}
</style>
